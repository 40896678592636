@use "themes"as *;

:root {
  --white0: #ffffff;
  --white1: #fbfbfb;
  --white2: #edf2f9;
  --white3: #f7f6f3;

  --gray0: #f8f8f8;
  --gray1: #dbe1e8;
  --gray1-rgb: 219, 225, 232;
  --gray2: #ededed;
  --gray3: #b2becd;
  --gray4: #a3a398;
  --gray5: #6c7983;
  --gray6: #454e56;
  --gray6-rgb: 69, 78, 86;
  --gray7: #2a2e35;
  --gray7-rgb: 42, 46, 53;
  --gray8: #12181b;


  --black-rgb: 0, 0, 0;
  --black0: #000000;
  --black1: #101010;

  --blue: #3584a7;
  --purple: #a82dd1;
  --yellow1: #efbb35;
  --yellow2: #fff3b0;

  --orange: #D35244;
  --red0: #e42525;
  --red1: #dc2f02;
  --red2: #e77878;
  --green0: rgb(52, 152, 115);
  --green0-rgb: 52, 152, 115;
  --green1: #008f68;
  --green2: #6db65b;

  --top-border-radius: 5px 5px 0 0;
  --no-border-radius: 0;

  --light-theme-primary: var(--white3);
  --dark-theme-primary: var(--gray8);
  --aqua-theme-primary: linear-gradient(-225deg,
      #473b7b 0%,
      #3584a7 51%,
      #30d2be 100%);
}

.light {
  --primary: var(--light-theme-primary);
  --secondary: var(--gray4);
  --bg: var(--white2);
  --bg-content: var(--white0);
  --text-color: var(--gray6);
  --header-text-color: var(--secondary);

  --theme-circle-border: var(--yellow1);
  --category-header-bg: var(--primary);
  --category-header-text: var(--gray5);
  --box-shadow: rgba(var(--black-rgb), 0.2);
  --bookmark-border: var(--gray1);

  --footer-link-hover: var(--orange);
  --bookmark-btn: var(--text-color);
  --bookmark-btn-remove: var(--red0);

  --field-input: var(--gray6);
  --field-border: var(--gray3);
  --field-error: var(--red0);

  --toggle-bg: rgba(var(--gray6-rgb), .9);
  --toggle-bg-hover: rgba(var(--gray6-rgb), 1);
  --toggle-active-bg: rgba(var(--green0-rgb), .9);
  --toggle-active-bg-hover: rgba(var(--green0-rgb), 1);
  --toggle-active-text: rgba(var(--green0-rgb), 1);

  --modal-overlay: rgba(var(--black-rgb), 0.5);
  --modal-button-primary: rgba(var(--green0-rgb), 1);
  --modal-button-primary-focus-shadow: rgba(var(--green0-rgb), .5);
  --modal-button-secondery: var(--gray6);
  --modal-button-secondery-focus-shadow: rgba(var(--gray6-rgb), .5);
  --modal-button-background: var(--white0);
  --modal-button-hover-text: var(--modal-button-background);

  --board-popup-hover-text: var(--gray5);
  --board-popup-control-border: var(--gray1);
  --modal-subbookmark-border: var(--gray1);

  --add-category-border: var(--gray3);

  --category-border-radius: var(--top-border-radius);
  --hover-brighten: brightness(70%);

  &__theme::before {
    background: var(--light-theme-primary);
  }
}

.dark {
  --primary: var(--dark-theme-primary);
  --secondary: var(--gray2);
  --bg: var(--gray7);
  --bg-content: var(--gray6);
  --text-color: var(--gray1);
  --header-text-color: var(--secondary);

  --theme-circle-border: var(--gray2);
  --category-header-bg: var(--primary);
  --category-header-text: var(--gray2);
  --box-shadow: rgba(var(--black-rgb), 0.2);
  --bookmark-border: var(--gray1);

  --footer-link-hover: var(--orange);
  --bookmark-btn: var(--text-color);
  --bookmark-btn-remove: var(--red1);

  --field-input: var(--gray6);
  --field-border: var(--gray3);
  --field-error: var(--red2);

  --toggle-bg: rgba(var(--gray1-rgb), .9);
  --toggle-bg-hover: rgba(var(--gray1-rgb), 1);
  --toggle-active-bg: rgba(var(--green0-rgb), .9);
  --toggle-active-bg-hover: rgba(var(--green0-rgb), 1);
  --toggle-active-text: rgba(var(--green0-rgb), 1);

  --modal-overlay: rgba(var(--black-rgb), 0.5);
  --modal-button-primary: rgba(var(--green0-rgb), 1);
  --modal-button-primary-focus-shadow: rgba(var(--green0-rgb), .5);
  --modal-button-secondery: var(--gray3);
  --modal-button-secondery-focus-shadow: rgba(var(--gray6-rgb), .5);
  --modal-button-background: var(--white0);
  --modal-button-hover-text: var(--modal-button-background);

  --board-popup-hover-text: var(--bg-content);
  --board-popup-control-border: var(--gray1);
  --modal-subbookmark-border: var(--gray1);

  --add-category-border: var(--gray3);

  --category-border-radius: var(--top-border-radius);
  --hover-brighten: brightness(120%);

  &__theme::before {
    background: var(--dark-theme-primary);
  }
}

.aqua {
  --primary: var(--aqua-theme-primary);
  --secondary: var(--blue);
  --bg: var(--white2);
  --bg-content: var(--white1);
  --text-color: var(--gray6);
  --header-text-color: var(--secondary);

  --theme-circle-border: var(--yellow1);
  --category-header-bg: var(--primary);
  --category-header-text: var(--white0);
  --box-shadow: rgba(var(--black-rgb), 0.2);
  --bookmark-border: var(--gray1);

  --footer-link-hover: var(--orange);
  --bookmark-btn: var(--text-color);
  --bookmark-btn-remove: var(--red0);

  --field-input: var(--gray6);
  --field-border: var(--gray3);
  --field-error: var(--red0);

  --toggle-bg: rgba(var(--gray6-rgb), .9);
  --toggle-bg-hover: rgba(var(--gray6-rgb), 1);
  --toggle-active-bg: rgba(var(--green0-rgb), .9);
  --toggle-active-bg-hover: rgba(var(--green0-rgb), 1);
  --toggle-active-text: rgba(var(--green0-rgb), 1);

  --modal-overlay: rgba(var(--black-rgb), 0.5);
  --modal-button-primary: rgba(var(--green0-rgb), 1);
  --modal-button-primary-focus-shadow: rgba(var(--green0-rgb), .5);
  --modal-button-secondery: var(--gray6);
  --modal-button-secondery-focus-shadow: rgba(var(--gray6-rgb), .5);
  --modal-button-background: var(--white0);
  --modal-button-hover-text: var(--modal-button-background);

  --board-popup-hover-text: var(--bg-content);
  --board-popup-control-border: var(--gray1);
  --modal-subbookmark-border: var(--gray1);

  --add-category-border: var(--gray3);

  --category-border-radius: var(--no-border-radius);
  --hover-brighten: brightness(70%);

  &__theme::before {
    background: var(--aqua-theme-primary);
  }
}



*,
*:before,
*:after {
  box-sizing: border-box;
}

@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@200..900');

html {
  height: 100%;
}

body {
  height: 100%;
  margin: 0px;
  display: flex;
  flex-direction: column;
  background: var(--bg);
  font-family: "Work Sans", "Segoe UI", Helvetica;
  color: var(--text-color);
}

a {
  text-decoration: none;
  color: currentColor;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.link-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: inline;
  margin: 0;
  padding: 0;
  color: currentColor;
}

.link-button:hover,
.link-button:focus {
  outline: none;
  filter: var(--hover-brighten);
}


.root {
  flex: 1 0 auto;
  padding: 5px;
}

.app {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.footer {
  flex-shrink: 0;
  padding: 5px;
  text-align: center;

  &__text {
    background: var(--bg-content);
    margin-bottom: 3px;
    padding: 2px 15px 3px 15px;
    border-radius: 5px;
    font-size: 1rem;
    box-shadow: 0px 0px 5px 0px var(--box-shadow);
    display: inline-block;

    a {
      font-weight: 700;

      &:hover {
        color: var(--footer-link-hover);
      }

    }
  }
}


.btn {
  display: none;
  border: none;
  background: transparent;
  color: var(--bookmark-btn);
  opacity: 0.8;
  cursor: pointer;

  &--edit {
    position: absolute;
    top: 8px;
    right: 35px;
    padding: 0px;
  }

  &--remove {
    position: absolute;
    top: 8px;
    right: 15px;
    padding: 0px;
    color: var(--bookmark-btn-remove) !important;
  }

  &--reorder {
    position: absolute;
    top: 8px;
    left: 0px;
    padding: 0px;
  }

  &--add-board {
    padding: 0px 7px;
  }

  &--edit-board {
    padding: 0px 0px 0px 7px;
  }
}

.button {
  background: var(--modal-button-background);
  border-radius: 2px;
  margin: 0 0.5rem;
  padding: 0.5rem 2rem;

  &--primary {
    border: 1px solid var(--modal-button-primary);
    color: var(--modal-button-primary);

    &:hover {
      background: var(--modal-button-primary);
      color: var(--modal-button-hover-text);
    }

    &:focus {
      box-shadow: 0 0 0 0.2rem var(--modal-button-primary-focus-shadow);
      outline: none;
    }
  }

  &--secoundary {
    border: 1px solid var(--modal-button-secondery);
    color: var(--modal-button-secondery);

    &:hover {
      background: var(--modal-button-secondery);
      color: var(--modal-button-hover-text);
    }

    &:focus {
      box-shadow: 0 0 0 0.2rem var(--modal-button-secondery-focus-shadow);
      outline: none;
    }
  }

}

.header {
  margin: 5px 0 15px;
  color: var(--header-text-color);
}

.board-name {
  font-weight: 500;
  font-size: 1.3rem;
}

.board-menu {
  position: relative;
  display: inline-block;

  &__content {
    display: none;
    position: absolute;
    background: var(--bg-content);
    min-width: 150px;
    box-shadow: 0px 8px 16px 0px var(--box-shadow);
    z-index: 1;
    border: 1px solid var(--board-popup-control-border);
    border-radius: 0 0 5px 5px;
  }

  .menu-item {
    display: block;
    padding: 4px 8px 4px 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-decoration: none;
    color: inherit;

    &:hover,
    &:focus {
      text-decoration: none;
      outline: none;
      color: var(--board-popup-hover-text);
      background: var(--primary);
    }
  }

  &:focus-within &__content {
    display: block;

  }
}

.board-action {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  &__button {
    background: var(--bg-content);
    flex-grow: 1;
    padding: 3px;
    border: none;
    border-bottom: 1px solid var(--board-popup-control-border);
    color: inherit;

    &:hover {
      color: var(--board-popup-hover-text);
      background: var(--primary);
    }


    &.right-border {
      border-right: 1px solid var(--board-popup-control-border);
    }
  }
}

.theme {
  margin-left: 30px;
  display: inline-block;


  &__selected {
    content: ' ';
    border: 2px solid var(--theme-circle-border);
    background: var(--primary);
    border-radius: 50%;
    width: 1.5rem;
    height: 1.5rem;
    display: inline-block;

    &:hover {
      filter: var(--hover-brighten);
    }
  }

  & a:hover {
    filter: var(--hover-brighten);
  }

  &__dropdown {
    position: absolute;
    width: 300px;
    display: none;
    z-index: 1;
    background: var(--bg-content);
    border-top: 2px solid var(--primary);
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
    align-items: center;
    justify-content: space-around;
    height: 4rem;
    margin-top: 0.2rem;
    padding: 0.5rem;
    box-shadow: 0px 0.175em 0.5em var(--box-shadow);
    //transition: opacity .15s ease-out;
    //transform: translateX(-40%); //algin centre
  }

  &:focus-within &__dropdown {
    display: flex;
    pointer-events: auto;
  }

  &__item {
    .theme__item-color {
      width: 100%;
      height: 100%;
      font-size: 0.7rem;
      display: block;
      text-align: center;
      text-decoration: none;

      &::before {
        content: ' ';
        border: 2px solid var(--theme-circle-border);
        border-radius: 50%;
        width: 2rem;
        height: 2rem;
        display: block;
        margin: 3px auto;
      }

      &:hover {
        filter: var(--hover-brighten);
      }
    }

  }
}

.login {
  float: right;
  text-decoration: underline;

  &__link {
    font-size: 1rem;

    &:hover {
      filter: var(--hover-brighten);
    }
  }
}


.categories {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  position: relative;
}

.category {
  width: 100%;
  min-width: 300px;
  margin: 0.2rem;
  background: var(--bg-content);
  position: relative;
  overflow: hidden;
  border-radius: var(--category-border-radius);

  &--empty {
    background: transparent;
    border: dashed 2px var(--add-category-border);
    min-height: 100px;

    .button-category-add {
      width: 100%;
      background: var(--bg-content);
      position: absolute;
      top: 40%;
      border: 1px solid var(--add-category-border);
      padding: 5px;
      opacity: 0.8;

      &:hover {
        opacity: 1;
      }
    }
  }

  &__header {
    background: var(--category-header-bg);
    color: var(--category-header-text);
    padding: 0.5rem 1.5rem;
    display: flex;
    align-items: center;

    &:hover {

      .btn--reorder,
      .btn--edit,
      .btn--remove {
        display: inline-block;
        color: var(--category-header-text);

        &:hover {
          opacity: 1;
        }

        &:focus {
          outline: none;
        }
      }
    }
  }

  &__body {
    background: var(--bg-content);
  }

  &__corner-ribbon {
    display: none;
    position: absolute;
    width: 45px;
    height: 45px;
    bottom: -25px;
    right: -25px;
    background: var(--secondary);
    text-align: center;
    line-height: 10px;
    letter-spacing: 1px;
    transform: rotate(-45deg);
    color: var(--bg-content);
    cursor: pointer;

    &:hover {
      filter: var(--hover-brighten);
    }
  }

  &:hover {
    .category__corner-ribbon {
      display: inline-block;
    }
  }
}

.bookmark {
  position: relative;
  padding: 5px 20px;
  display: block;
  border-bottom: 1px solid var(--bookmark-border);

  &:hover {

    .btn--reorder,
    .btn--edit,
    .btn--remove {
      display: inline-block;

      &:hover {
        opacity: 1;
      }

      &:focus {
        outline: none;
      }
    }
  }

  &__name {
    color: inherit;
    text-decoration: none;
    display: inline-block;
    width: 100%;

    &:hover {
      color: inherit;
      text-decoration: none;
    }
  }

  &__favicon {
    margin-right: 5px;
    display: inline-block;
    height: 100%;
    vertical-align: middle;
  }

  &__description {
    opacity: 0.8;
    font-size: .9rem;
    font-weight: 400;
  }

  &__child_item {
    display: inline-block;
    padding: 0.25em 0.4em;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    background: var(--secondary);
    color: var(--bg-content);
    font-size: 0.8rem;
    font-weight: 400;
    margin-right: 0.25rem;
    text-decoration: none;
    text-transform: uppercase;
  }
}

// ### form styling from here

label {
  display: flex;
  margin: 1rem 0 0.5rem;
}

input[type="email"],
input[type="text"],
input[type="number"],
textarea {
  color: var(--field-input);
  width: 100%;
  padding: 0.65rem 0.5rem;
  font-size: 1rem;
  border: 1px solid var(--field-border);
  box-sizing: border-box;

  &:focus {
    box-shadow: 0 0 0 0.1rem var(--box-shadow);
    outline: none;
  }
}

textarea {
  height: 50px;
  display: flex;

  &:focus {
    box-shadow: 0 0 0 0.1rem var(--box-shadow);
    outline: none;
  }
}

.error {
  font-size: 12px;
  color: var(--field-error);
  margin: 0.2rem 0 0 1rem;
}

input.error-field {
  border: 1px solid var(--field-error);
}

.modal-action {
  margin-top: 2rem;
  display: flex;
  justify-content: flex-end;
}

.toggle {
  position: relative;
  max-width: 200px;

  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }

  input[type="checkbox"] {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    padding: 0;

    &:focus~label .toggle__switch,
    &:hover~label .toggle__switch {
      background: var(--toggle-bg-hover);
    }

    &:checked {

      &:focus~label .toggle__switch,
      &:hover~label .toggle__switch {
        background: var(--toggle-active-bg-hover);
      }

      ~label .toggle__switch {
        background: var(--toggle-active-bg);

        &:before {
          content: attr(data-unchecked);
          left: 0;
        }

        &:after {
          transform: translate3d(28px, 0, 0);
          color: var(--toggle-active-text);
          content: attr(data-checked);
        }
      }
    }
  }

  label {
    user-select: none;
    position: relative;
    display: flex;
    align-items: center;

    .toggle__label-text {
      font-size: 1rem;
      padding-right: 30px;
    }
  }

  .toggle__switch {
    font-size: 10px;
    height: 30px;
    -webkit-box-flex: 0;
    flex: 0 0 60px;
    border-radius: 30px;
    transition: background-color 0.3s cubic-bezier(0.86, 0, 0.07, 1);
    background: var(--toggle-bg);
    position: relative;

    &:before {
      left: 30px;
      font-size: 10px;
      line-height: 30px;
      width: 30px;
      padding: 0 4px;
      color: var(--bg-content);
      content: attr(data-checked);
      position: absolute;
      top: 0;
      text-transform: uppercase;
      text-align: center;
    }

    &:after {
      font-weight: 700;
      top: 2px;
      left: 2px;
      border-radius: 15px;
      width: 28px;
      line-height: 26px;
      font-size: 10px;
      transition: transform 0.3s cubic-bezier(0.86, 0, 0.07, 1);
      color: var(--toggle-active-text);
      content: attr(data-unchecked);
      position: absolute;
      z-index: 5;
      text-transform: uppercase;
      text-align: center;
      background: var(--bg-content);
      transform: translate3d(0, 0, 0);
    }
  }
}

.form-sub-bookmarks {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;

  th {
    text-align: left;
    font-weight: normal;

    &.col-name {
      width: 120px;
      padding-left: 5px;
    }

    &.col-url {
      padding-left: 5px;
    }

    &.col-delete {
      width: 25px;
    }
  }

  td {
    border-bottom: 1px solid var(--modal-subbookmark-border);
    border-top: 1px solid var(--modal-subbookmark-border);
    padding: 5px 0px;
  }

  input {
    width: 98%;
  }

  &__remove {
    .hover-buttons {
      cursor: pointer;
    }
  }

  &__buttons {
    display: none;
    background: var(--bg-content);
    border: none;
    width: 20px;
    padding: 0px;
    height: 30px;
  }

  tr:hover {
    .form-sub-bookmarks__buttons {
      display: inline-block;
    }
  }

  tr.tr-invisible {
    display: none;
  }
}

// ## reactmodal from
.ReactModal__Body--open {
  overflow: hidden;
}

.ReactModal__Overlay {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background: var(--modal-overlay);
  display: flex;
  align-items: center;
  justify-content: center;
}

.ReactModal__Content {
  border: 1px solid var(--box-shadow);
  background: var(--bg-content);
  overflow: auto;
  border-radius: 4px;
  outline: none;
  padding: 20px;
  width: 50%;
  min-width: 400px;
  max-height: calc(100vh - 2rem);
}

// ## reactmodal to

// ### form styling till here

.loading {
  display: none;
  background: var(--bg-content);
  //color: #666666;
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 1000;
  top: 0;
  left: 0;
  float: left;
  text-align: center;
  padding-top: 25%;
  opacity: .80;

  &.show {
    display: block;
  }

  &__spinner {
    margin: 0 auto;
    height: 64px;
    width: 64px;
    animation: rotate 0.8s infinite linear;
    border: 5px solid var(--secondary);
    border-right-color: transparent;
    border-radius: 50%;
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@media (min-width: 650px) {
  .app {
    width: 650px;
  }

  .category {
    width: 300px;
  }
}

@media (min-width: 950px) {
  .app {
    width: 950px;
  }
}

@media (min-width: 1300px) {
  .app {
    width: 1300px;
  }
}